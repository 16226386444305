import { Collapse } from '@finalytic/components';
import { showApiErrorNotification, useApiClient } from '@finalytic/data';
import { LazyTable, type MRT_ColumnDef } from '@finalytic/table';
import { type Maybe, formatCurrency, sum } from '@finalytic/utils';
import { Anchor, Box, Center, HoverCard, List, rem } from '@mantine/core';
import { Text } from '@mantine/core';
import { useQuery as useTanstackQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { FEE_TEMPLATES } from '../../../overview/_components';
import { useFeeForm } from '../../useFeeForm';
import { useFeeAccountsTableFilter } from '../FeeAccountsTable';
import {
  type FeeReservationPreviewCalculationProps,
  useFeeReservationPreviewCalculation,
} from './useFeeReservationPreviewCalculation';

export type PreviewRow = {
  id: string;
  type: 'financial' | 'fee' | 'deposit' | 'expense';
  title: string;
  centTotal: number;
  accounts: { id: string; centTotal: number }[];
  subRows?: PreviewRow[];
};

function usePreviewQuery(reservationId: Maybe<string>) {
  const $api = useApiClient();
  const methods = useFeeForm();

  const linkedAccountIds = useWatch({
    control: methods.control,
    name: 'linkedAccountIds',
  });
  const title = useWatch({
    control: methods.control,
    name: 'title',
  });
  const type = useWatch({
    control: methods.control,
    name: 'type',
  });

  const defaultRateBasePoints = useWatch({
    control: methods.control,
    name: 'defaultRateBasePoints',
  });

  const bookingChannelsFilter = useWatch({
    control: methods.control,
    name: 'bookingChannelsFilter',
  });

  const creditAccountId = useWatch({
    control: methods.control,
    name: 'creditAccountId',
  });

  const debitAccountId = useWatch({
    control: methods.control,
    name: 'debitAccountId',
  });

  const revenueRecognition = useWatch({
    control: methods.control,
    name: 'revenueRecognition',
  });

  const statusFilter = useWatch({
    control: methods.control,
    name: 'statusFilter',
  });

  const taxRateId = useWatch({
    control: methods.control,
    name: 'taxRateId',
  });

  return useTanstackQuery({
    queryKey: [
      'fee-reservation-preview',
      reservationId,
      linkedAccountIds,
      title,
      defaultRateBasePoints,
      type,
      bookingChannelsFilter,
      creditAccountId,
      debitAccountId,
      revenueRecognition,
      statusFilter,
      taxRateId,
    ],
    queryFn: async () => {
      if (!reservationId) return null;

      const res = await $api.POST('/recurring-fees/preview', {
        body: {
          reservationId,
          type,
          defaultRate: defaultRateBasePoints,
          formula: FEE_TEMPLATES[type].config.formulaTemplate.replace(
            'accounts',
            linkedAccountIds.map((x) => `"${x}"`).join(' + ')
          ),
          creditAccountId,
          debitAccountId,
          bookingChannelsFilter:
            bookingChannelsFilter === 'all-channels'
              ? null
              : bookingChannelsFilter,
          revenueRecognition,
          statusFilter,
          taxRateId,
        },
      });

      if (res.error) {
        showApiErrorNotification({
          error: res.error,
          title: 'Failed to preview fee',
          defaultMessage:
            'We failed to preview the fee. Please select a different reservation and try again. Contact support if the issue persists.',
        });

        throw new Error('Failed to preview fee');
      }

      return res.data;
    },
    enabled: !!reservationId,
  });
}

export const FeeReservationPreviewTable = (
  props: FeeReservationPreviewCalculationProps
) => {
  const { setFilter } = useFeeAccountsTableFilter();

  const methods = useFeeForm();
  const percentage = (methods.watch('defaultRateBasePoints') ?? 0) / 10000;

  const { reservation } = props;

  const { data: preview } = usePreviewQuery(reservation?.id);
  console.log(preview);

  const isCancelled = reservation?.status === 'cancelled';
  const currency = reservation?.currency ?? 'usd';

  const { rowData, selectedAccounts, selectedSumCentTotal, sumCentTotal } =
    useFeeReservationPreviewCalculation(props);

  const columns = useMemo<MRT_ColumnDef<PreviewRow>[]>(
    () => [
      {
        header: 'Item',
        Header: '',
        mantineTableFooterCellProps: {
          pl: 'xs',
        },
        mantineTableBodyCellProps: {
          px: 'xs',
        },
        Footer: () => (
          <Text span fw={500}>
            Total charged:
          </Text>
        ),
        AggregatedCell: ({ row }) => {
          const title = row.original.title;
          return <Text fw={500}>{title}</Text>;
        },
        Cell: ({ row }) => {
          const title = row.original.title;
          const td = isCancelled ? 'line-through' : undefined;

          const fw = !row.parentId ? 500 : undefined;

          const accountIds = row.original.accounts.map((x) => x.id);

          const accounts = props.accounts.filter((x) =>
            accountIds.includes(x.id)
          );

          return (
            <HoverCard
              // disabled={!accounts.length}
              shadow="md"
              withArrow
              withinPortal
              position="top"
              radius="md"
            >
              <HoverCard.Target>
                <Box>
                  <Text td={td} fw={fw}>
                    {title}
                  </Text>
                </Box>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text fw={500} mb={rem(5)}>
                  Accounts
                </Text>
                {accounts.length ? (
                  <List icon={null} size="sm">
                    {accounts.map((x) => (
                      <List.Item key={x.id}>
                        <Anchor
                          component="button"
                          onClick={() => setFilter({ search: x.title })}
                          c="dark"
                        >
                          {x.title}
                        </Anchor>
                      </List.Item>
                    ))}
                  </List>
                ) : (
                  <Text c="gray">
                    {row.original.type === 'financial'
                      ? 'No accounts mapped to this line'
                      : 'No accounts found'}
                  </Text>
                )}
              </HoverCard.Dropdown>
            </HoverCard>
          );
        },
      },
      {
        header: 'Fee',
        maxSize: 90,
        mantineTableBodyCellProps: {
          align: 'right',
          sx: (theme) => ({
            backgroundColor: theme.colors.yellow[0],
          }),
        },
        mantineTableFooterCellProps: {
          align: 'right',
          pr: 'xs',
          sx: (theme) => ({
            backgroundColor: theme.colors.yellow[0],
          }),
        },
        mantineTableHeadCellProps: {
          align: 'right',
          pr: 'xs',
          sx: (theme) => ({
            backgroundColor: theme.colors.yellow[0],
            fontWeight: 400,
            color: theme.colors.gray[7],
          }),
        },
        AggregatedCell: ({ row }) => {
          const selectedAmounts =
            row.subRows
              ?.flatMap((x) => x.original.accounts)
              ?.filter((x) => {
                return selectedAccounts.map((y) => y.id).includes(x.id);
              }) ?? [];

          const total = sum(selectedAmounts, 'centTotal');

          if (!total) return '-';

          const amount = formatCurrency((total * percentage) / 100, currency);

          return (
            <Text td={isCancelled ? 'line-through' : undefined} fw={500}>
              {amount}
            </Text>
          );
        },
        Cell: ({ row }) => {
          const rowSelectedAccounts = row.original.accounts.filter((x) =>
            selectedAccounts.map((y) => y.id).includes(x.id)
          );

          const isSelected = !!rowSelectedAccounts;

          if (!isSelected) return '-';

          const centTotal = sum(rowSelectedAccounts, 'centTotal') * percentage;

          if (!centTotal) return '-';

          const amount = formatCurrency(centTotal / 100, currency);

          const fw = !row.parentId ? 500 : undefined;

          return (
            <Text td={isCancelled ? 'line-through' : undefined} fw={fw}>
              {amount}
            </Text>
          );
        },
        Footer: () => (
          <Text span fw={500} ta="right" display={'block'}>
            {formatCurrency(selectedSumCentTotal / 100, currency)}
          </Text>
        ),
      },
      {
        header: 'Total',
        maxSize: 90,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableHeadCellProps: {
          align: 'right',
          pr: 'xs',
        },
        mantineTableFooterCellProps: {
          align: 'right',
          pr: 'xs',
        },
        Cell: ({ row }) => {
          const amount = formatCurrency(
            (row.original.centTotal || 0) / 100,
            currency
          );

          const fw = !row.parentId ? 500 : undefined;

          return (
            <Text td={isCancelled ? 'line-through' : undefined} fw={fw}>
              {amount}
            </Text>
          );
        },
        AggregatedCell: ({ row }) => {
          return (
            <Text fw={500}>
              {formatCurrency(row.original.centTotal / 100, currency)}
            </Text>
          );
        },
        Footer: () => (
          <Text span fw={500} ta="right" display={'block'}>
            {formatCurrency(sumCentTotal / 100, currency)}
          </Text>
        ),
      },
    ],
    [
      currency,
      sumCentTotal,
      selectedSumCentTotal,
      isCancelled,
      percentage,
      selectedAccounts,
      props.accounts,
      setFilter,
    ]
  );

  return (
    <Box>
      <Collapse
        title={
          <Text component="span" size="sm">
            Preview
          </Text>
        }
        rightSection={null}
        minHeight={30}
        defaultOpened
      >
        <Box>
          <LazyTable
            table={{
              columns,
              hideHeader: true,
              hideTopBar: true,
              emptyRowsFallback: () => (
                <Center>
                  <Text size="sm" c="gray">
                    No lines found
                  </Text>
                </Center>
              ),
              onRowClick: {
                disabled: (row) => row.original.accounts?.length !== 1,
                handler: (row) => {
                  const account = props.accounts.find((x) =>
                    row.original.accounts.map((y) => y.id).includes(x.id)
                  );

                  if (account) setFilter({ search: account.title });
                },
              },
            }}
            subRows={{
              getRowCanExpand: (row) => !!row.original.subRows?.length,
              defaultExpanded: true,
              getSubRows: (row) => row.subRows ?? [],
            }}
            data={{
              rows: rowData,
              error: null,
              loading: false,
              rowCount: rowData.length,
            }}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
