import { Filter } from '@finalytic/components';
import { type SelectItem, StringParam, useQueryParams } from '@finalytic/ui';
import { day, hasValue } from '@finalytic/utils';
import { Box, Group } from '@mantine/core';
import { useMemo } from 'react';
import type { whereBankRecords } from './useWhereBankRecords';

export const useBankRecordsTableFilter = () => {
  const [filter, setFilter] = useQueryParams({
    search: StringParam,
    date: StringParam,
    reconciliation: StringParam,
  });

  return {
    filter,
    setFilter,
    reset: () => {
      const initial = {
        search: undefined,
        date: undefined,
        reconciliation: undefined,
      };

      setFilter(initial);
    },
  };
};

export const BankRecordsTableFilter = () => {
  return (
    <Group>
      <Search />
      <Date />
      <Reconciliation />
    </Group>
  );
};

const Search = () => {
  const { filter, setFilter } = useBankRecordsTableFilter();

  return (
    <Filter.Search
      value={filter.search || ''}
      setValue={(v) => setFilter({ search: v })}
    />
  );
};

const Date = () => {
  const { filter, setFilter } = useBankRecordsTableFilter();

  return (
    <Filter.Date
      value={filter.date || undefined}
      setValue={(value) => {
        setFilter({
          date: value
            ?.filter(hasValue)
            .map((date) => day(date).yyyymmdd())
            .join('...'),
        });
      }}
    />
  );
};

const Reconciliation = () => {
  const { filter, setFilter } = useBankRecordsTableFilter();

  type Rec = NonNullable<
    Parameters<typeof whereBankRecords>[0]['reconciliation']
  >;

  const options = useMemo<SelectItem<Rec>[]>(
    () => [
      {
        label: 'Reconciled',
        value: 'reconciled',
        icon: (
          <Box
            w={10}
            h={10}
            sx={(theme) => ({
              alignSelf: 'center',
              borderRadius: theme.radius.xl,
              backgroundColor: theme.colors.green[6],
            })}
          />
        ),
      },
      {
        label: 'Unreconciled',
        value: 'unreconciled',
        icon: (
          <Box
            w={10}
            h={10}
            sx={(theme) => ({
              alignSelf: 'center',
              borderRadius: theme.radius.xl,
              backgroundColor: theme.colors.yellow[6],
            })}
          />
        ),
      },
    ],
    []
  );

  const value = options.find((o) => o.value === filter.reconciliation) ?? null;

  return (
    <Filter.Select
      value={value}
      type="single"
      label="Reconciliation"
      data={{
        options,
      }}
      setValue={(v) => setFilter({ reconciliation: v?.value })}
      hideIcon
    />
  );
};
