import { gqlV2, useMe, useTeam } from '@finalytic/data';
import { useQuery } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { LoadingIndicator, type SelectItem } from '@finalytic/ui';
import { utc } from '@finalytic/utils';
import { Box, Group, rem } from '@mantine/core';
import { Combobox, LoadingOverlay, Text, useCombobox } from '@mantine/core';
import { Avatar } from '@mantine/core';
import { getListingName, whereReservations } from '@vrplatform/ui-common';
import { getLedgerReservationDetail } from '../../../../../drawers/reservation-drawers/_queries/useLedgerReservationDetailDrawerQuery';

export type FeeReservation = NonNullable<
  ReturnType<typeof getLedgerReservationDetail>
>;

const getDropdownReservationDescription = (reservation: gqlV2.reservation) => {
  return (
    <>
      {`${utc(reservation.checkIn).format('ll')} - ${utc(
        reservation.checkOut
      ).format(
        'll'
      )}${reservation.guests ? ` - ${reservation.guests} guests` : ''}`}
      <br />
      {getListingName(reservation.listing)}
    </>
  );
};

export function useFeeReservationDropdownQuery({ search }: { search: string }) {
  const [{ id: teamId }] = useTeam();
  const { id: meId } = useMe();

  return useQuery(
    (q, args) => {
      const limit = 30;

      const where = whereReservations({
        search: args.search,
        currentTeamId: args.teamId,
        dashboard: 'propertyManager',
        meId: args.meId,
        partnerTeamIds: [],
        includeLines: false,
        GL: true,
      });

      return q
        .reservations({
          where,
          order_by: [{ checkIn: 'desc_nulls_last' }],
          limit,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: res.guestName || 'No name',
          description: getDropdownReservationDescription(res),
        }));
    },
    {
      skip: !teamId,
      queryKey: ['listing', 'reservations'],
      keepPreviousData: true,
      variables: {
        teamId,
        search: search?.trim(),
        meId,
      },
    }
  );
}

export const FeeReservationSelect = ({
  search,
  setSearch,
  setSelectedReservationId,
  listData,
  loadingList,
  reservation,
}: {
  search: string;
  setSearch: (search: string) => void;
  setSelectedReservationId: (id: string | null) => void;
  listData: SelectItem[];
  loadingList: boolean;
  reservation: ReturnType<typeof getLedgerReservationDetail>;
}) => {
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },

    onDropdownOpen: () => {
      combobox.focusSearchInput();
    },
  });

  const options = listData.map((item) => (
    <Combobox.Option
      value={item.value}
      key={item.value}
      disabled={!!item.disabled}
    >
      {item.label}
      {item.description && (
        <>
          <br />
          <Text span c="gray" size="xs">
            {item.description}
          </Text>
        </>
      )}
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      withinPortal
      onOptionSubmit={(val) => {
        setSelectedReservationId(val);
        combobox.closeDropdown();
      }}
      withArrow
      position="bottom"
      shadow="md"
      width={400}
    >
      <Combobox.Target>
        <Box
          component="button"
          type="button"
          onClick={() => combobox.toggleDropdown()}
          sx={(theme) => {
            const activeStyles = {
              boxShadow: `0px 0px 0px 2px ${
                theme.colors[theme.primaryColor][4]
              }40`,
              borderColor: theme.colors[theme.primaryColor][6],
            };

            return {
              all: 'unset',
              width: '100%',
              position: 'sticky',
              top: 0,
              zIndex: 100,
              borderRadius: theme.radius.md,
              cursor: 'pointer',
              border: `1px solid ${theme.colors.gray[2]}`,
              paddingBlock: rem(5),
              boxShadow: theme.shadows.sm,
              outline: 'none',
              backgroundColor: 'white',
              ...(combobox.dropdownOpened ? activeStyles : {}),
            };
          }}
        >
          <Group justify="space-between" mx="xs">
            <Group flex={1} gap="md">
              {reservation ? (
                <Avatar src={reservation.connection.app.iconRound} />
              ) : (
                <LoadingIndicator size="xs" />
              )}
              {reservation && (
                <Box
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  <Text fw={500} size="xl" lh="xs">
                    {reservation.guestName}
                  </Text>
                  <Text>{reservation.confirmationCode}</Text>
                </Box>
              )}
            </Group>

            <Icon
              icon="ChevronIcon"
              size={18}
              color={(theme) => theme.colors.gray[4]}
            />
          </Group>
        </Box>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Search
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder="Search ..."
        />
        <Combobox.Options
          mah={340}
          style={{ overflowY: 'auto', position: 'relative' }}
        >
          {options?.length > 0 ? (
            options
          ) : (
            <Combobox.Empty>Nothing found</Combobox.Empty>
          )}
          <LoadingOverlay visible={loadingList} />
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
