import { sum } from '@finalytic/utils';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useFeeForm } from '../../useFeeForm';
import type { FeeAccountRow } from '../FeeAccountsTable';
import type { PreviewRow } from './FeeReservationPreviewTable';
import type { FeeReservationPreview } from './useFeeReservationPreviewQuery';

export type FeeReservationPreviewCalculationProps = {
  reservation: FeeReservationPreview['reservation'];
  journalEntries: FeeReservationPreview['journalEntries'];
  accounts: FeeAccountRow[];
};

export function useFeeReservationPreviewCalculation({
  accounts,
  journalEntries,
  reservation,
}: FeeReservationPreviewCalculationProps) {
  const { id: currentRecurringFeeId } = useParams();

  const methods = useFeeForm();

  const accountIds = methods.watch('linkedAccountIds');
  const percentage = (methods.watch('defaultRateBasePoints') ?? 0) / 10000;

  console.log(percentage);

  const selectedAccounts = useMemo(() => {
    return accounts.filter((x) => accountIds?.includes(x.id));
  }, [accountIds, accounts]);

  const deposits = (reservation?.deposits ?? []).filter((deposit) =>
    journalEntries.some((y) => y.transaction.id === deposit.id)
  );
  const fees =
    reservation?.feesAndCommissions.filter(
      (x) => x.id !== currentRecurringFeeId
    ) ?? [];
  const expenses = (reservation?.expenses ?? []).filter((expense) =>
    journalEntries.some((y) => y.transaction.id === expense.id)
  );
  const financials =
    reservation?.financials.financials?.filter(
      (x) => x.isInvoice === 'invoice'
    ) ?? [];

  const rowData = useMemo<PreviewRow[]>(() => {
    const financialRows = financials.map<PreviewRow>((line) => {
      const accountId = accounts.find((x) =>
        x.lineItems.some((y) => y.lineType === line.type2)
      )?.id;

      const centTotal = line.centTotal;

      return {
        id: line.id,
        accounts: accountId ? [{ id: accountId, centTotal }] : [],
        centTotal: line.centTotal,
        type: 'financial',
        title: line.description || '-',
      };
    });

    const depositRows = deposits
      .map<PreviewRow>((deposit) => {
        const entries = journalEntries.filter(
          (x) => x.transaction.id === deposit.id
        );

        const centTotal = sum(entries, 'centTotal');

        return {
          id: deposit.id,
          type: 'deposit',
          title: deposit.description || '-',
          centTotal,
          accounts: entries.reduce<PreviewRow['accounts']>((acc, x) => {
            if (x.accountId) {
              const index = acc.findIndex((y) => y.id === x.accountId);

              if (index === -1)
                acc.push({ id: x.accountId, centTotal: x.centTotal });
              else acc[index].centTotal += x.centTotal;
            }

            return acc;
          }, []),
        };
      })
      .filter((x) => x.centTotal !== 0);

    const expenseRows = expenses
      .map<PreviewRow>((expense) => {
        const entries = journalEntries.filter(
          (x) => x.transaction.id === expense.id
        );

        const centTotal = sum(entries, 'centTotal');

        return {
          id: expense.id,
          type: 'expense',
          title: expense.description || '-',
          centTotal,
          accounts: entries.reduce<PreviewRow['accounts']>((acc, x) => {
            if (x.accountId) {
              const index = acc.findIndex((y) => y.id === x.accountId);

              if (index === -1)
                acc.push({ id: x.accountId, centTotal: x.centTotal });
              else acc[index].centTotal += x.centTotal;
            }

            return acc;
          }, []),
        };
      })
      .filter((x) => x.centTotal !== 0);

    const feeRows = fees
      .map<PreviewRow>((fee) => {
        const entries = journalEntries.filter(
          (x) => x.recurringFee.id === fee.id
        );

        const centTotal = sum(entries, 'centTotal');

        return {
          id: fee.id,
          type: 'fee',
          title: fee.title || '-',
          centTotal,
          accounts: entries.reduce<PreviewRow['accounts']>((acc, x) => {
            if (x.accountId) {
              const index = acc.findIndex((y) => y.id === x.accountId);

              if (index === -1)
                acc.push({ id: x.accountId, centTotal: x.centTotal });
              else acc[index].centTotal += x.centTotal;
            }

            return acc;
          }, []),
        };
      })
      .filter((x) => x.centTotal !== 0);

    return [
      {
        id: 'financials',
        type: 'financial',
        title: 'Financials',
        centTotal: sum(financialRows, 'centTotal'),
        accounts: [],
        subRows: financialRows,
      },
      {
        id: 'fees',
        type: 'fee',
        title: 'Fees & Commissions',
        centTotal: sum(feeRows, 'centTotal'),
        accounts: [],
        subRows: feeRows,
      },
      {
        id: 'deposits',
        type: 'deposit',
        title: 'Deposits',
        centTotal: sum(depositRows, 'centTotal'),
        accounts: [],
        subRows: depositRows,
      },
      {
        id: 'expenses',
        type: 'expense',
        title: 'Expenses',
        centTotal: sum(expenseRows, 'centTotal'),
        accounts: [],
        subRows: expenseRows,
      },
    ].filter((x) => x.subRows.length) as PreviewRow[];
  }, [financials, deposits, journalEntries, expenses, fees, accounts]);

  const sumCentTotal = useMemo(() => {
    return sum(rowData, 'centTotal');
  }, [rowData]);

  const selectedSumCentTotal = useMemo(() => {
    const selectedAccountIds = selectedAccounts.map((x) => x.id);

    return (
      sum(
        rowData
          .flatMap((x) => x.subRows ?? [])
          .flatMap((x) =>
            x.accounts.filter((y) => selectedAccountIds.includes(y.id))
          ),
        'centTotal'
      ) * percentage
    );
  }, [rowData, selectedAccounts, percentage]);

  return {
    rowData,
    sumCentTotal,
    selectedSumCentTotal,
    selectedAccounts,
  };
}
