import { StringParam, useQueryParam } from '@finalytic/ui';
import { Box, Skeleton, Stack } from '@mantine/core';
import { LoadingOverlay, useCombobox } from '@mantine/core';
import { useState } from 'react';
import { LedgerReservationInfoCard } from '../../../../../drawers/reservation-drawers/LedgerReservationDetailDrawer';
import { useFeeAccountsQuery } from '../FeeAccountsTable';
import { FeeReservationPreviewTable } from './FeeReservationPreviewTable';
import { FeeReservationPreviewTotal } from './FeeReservationPreviewTotal';
import {
  FeeReservationSelect,
  useFeeReservationDropdownQuery,
} from './FeeReservationSelect';
import { useFeeReservationPreviewQuery } from './useFeeReservationPreviewQuery';

type Props = {
  initialAccountIds: string[];
};

export const FEE_RESERVATION_PREVIEW_SEARCH_KEY = 'fee-reservation-preview';

export const FeeReservationPreview = (props: Props) => {
  return (
    <Box
      p="lg"
      pos="relative"
      flex={1}
      sx={{
        overflow: 'scroll',
      }}
    >
      <FeeContent {...props} />
    </Box>
  );
};

const FeeContent = (props: Props) => {
  const [search, setSearch] = useState('');

  const [selectedReservationId = null, setSelectedReservationId] =
    useQueryParam(FEE_RESERVATION_PREVIEW_SEARCH_KEY, StringParam);

  const { data: listData = [], isLoading: loadingList } =
    useFeeReservationDropdownQuery({
      search,
    });

  const { data, isLoading: loadingValue } = useFeeReservationPreviewQuery({
    reservationId: selectedReservationId || (listData?.[0]?.value ?? null),
  });

  const { data: accounts = [], isLoading: loadingAccounts } =
    useFeeAccountsQuery(props);

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },

    onDropdownOpen: () => {
      combobox.focusSearchInput();
    },
  });

  const loading = loadingValue || loadingAccounts;

  return (
    <>
      <LoadingOverlay visible={loading} />
      <FeeReservationSelect
        listData={listData}
        loadingList={loadingList}
        reservation={data?.reservation ?? null}
        search={search}
        setSearch={setSearch}
        setSelectedReservationId={setSelectedReservationId}
      />
      <Stack mt="md">
        {!data?.reservation ? (
          <>
            <Skeleton h={50} />
            <Skeleton h={50} />
            <Skeleton h={50} />
          </>
        ) : (
          <>
            <LedgerReservationInfoCard {...data.reservation} />

            <FeeReservationPreviewTotal
              reservation={data.reservation}
              journalEntries={data.journalEntries}
              accounts={accounts}
            />

            <FeeReservationPreviewTable
              reservation={data.reservation}
              journalEntries={data.journalEntries}
              accounts={accounts}
            />
          </>
        )}
      </Stack>
    </>
  );
};
