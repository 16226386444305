import { type gqlV2, useTeamId } from '@finalytic/data';
import { type Maybe, day, isUUID } from '@finalytic/utils';
import { useMemo } from 'react';
import { useBankAccountId } from '../useBankAccountId';
import { useBankRecordsTableFilter } from './BankRecordsTableFilter';

export function useWhereBankRecords() {
  const [teamId] = useTeamId();
  const id = useBankAccountId();
  const { filter } = useBankRecordsTableFilter();

  const trimmed = filter.search?.trim();

  return useMemo<gqlV2.bankRecord_bool_exp>(
    () =>
      whereBankRecords({
        tenantId: teamId,
        bankAccountId: id!,
        date: filter.date,
        search: trimmed,
        reconciliation: filter.reconciliation as Maybe<
          'reconciled' | 'unreconciled'
        >,
      }),
    [teamId, id, trimmed, filter.date, filter.reconciliation]
  );
}

export const whereBankRecords = ({
  tenantId,
  bankAccountId,
  search,
  date,
  reconciliation,
}: {
  tenantId: string;
  bankAccountId: string;
  search: Maybe<string>;
  date: Maybe<string>;
  reconciliation: Maybe<'reconciled' | 'unreconciled'>;
}): gqlV2.bankRecord_bool_exp => {
  const [start, end] = date?.split('...') || [];

  return {
    tenantId: { _eq: tenantId },
    bankAccountId: isUUID(bankAccountId) ? { _eq: bankAccountId } : undefined,
    date: start
      ? {
          _gte: start,
          _lt: end ? undefined : day(start).add(1, 'day').format('YYYY-MM-DD'),
          _lte: end ? end : undefined,
        }
      : undefined,
    _or: search
      ? [
          {
            description: { _ilike: `%${search}%` },
          },
          {
            uniqueRef: { _ilike: `%${search}%` },
          },
        ]
      : undefined,
    _and: reconciliation
      ? [
          {
            isBalanceAdjustment: reconciliation
              ? {
                  _eq: false,
                }
              : undefined,
            reconcileStatus:
              reconciliation === 'reconciled' ? { _eq: 'paid' } : undefined,
            _or:
              reconciliation === 'reconciled'
                ? undefined
                : [
                    {
                      reconcileStatus: {
                        _neq: 'paid',
                      },
                    },
                    {
                      reconcileStatus: {
                        _is_null: true,
                      },
                    },
                  ],
          },
        ]
      : undefined,
  };
};
