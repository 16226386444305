import type {
  transaction as Transaction,
  transactionLine_bool_exp,
  transactionLine_order_by,
} from '@finalytic/graphql';
import { type Maybe, ensure, hasValue, sum } from '@finalytic/utils';
import { getTransactionLineType } from '@vrplatform/ui-common';
import type {
  DepositFormInputs,
  DepositReservationLine,
} from './_hooks/useDepositForm';
import {
  getDepositManualLinesCentTotal,
  getDepositReservationLinesCentTotal,
} from './_utils';

type Extras = {
  transactionId: string;
  uniqueRef: Maybe<string>;
};

export const getDepositLines = (
  q: Transaction['lines'],
  opts?: {
    where?: transactionLine_bool_exp;
    orderBy?: transactionLine_order_by[];
  }
) => {
  const lines = q({
    order_by: opts?.orderBy || [{ createdAt: 'desc' }],
    where: opts?.where,
  }).map((line) => ({
    id: line.id,
    assignment: line.accountAssignmentType,
    centTotal: line.centTotal ?? 0,
    description: line.description || '',
    uniqueRef: line.uniqueRef,
    accountId: line.accountId,
    reservationId: (line.reservationId || null) as string | null,
    listingId: line.listingId,
    party: line.party,
    contactId: line.contactId,
    type: getTransactionLineType(line),
    transactionId: line.transactionId,
    paymentLineType: line.matchers({ path: 'lineType' }) ?? null,
  }));

  const linesWithReservation = lines.filter((x) => x.reservationId);

  const reduceLines = (l: typeof lines) =>
    l.reduce<typeof lines>((acc, line) => {
      const reservationId = line.reservationId;
      const index = acc.findIndex((x) => x.reservationId === reservationId);
      if (index === -1) acc.push(line);
      else acc[index].centTotal = acc[index].centTotal + line.centTotal;
      return acc;
    }, []);

  const payment_lines = reduceLines(
    linesWithReservation.filter(
      (x) => x.type === 'payment' || x.type === 'refund'
    )
  );

  const merchantFee_lines = reduceLines(
    linesWithReservation.filter((x) => x.type === 'merchantFee')
  );

  const channelFee_lines = reduceLines(
    linesWithReservation.filter((x) => x.type === 'channelFee')
  );

  const reserve_lines = reduceLines(
    linesWithReservation.filter((x) => x.type === 'reserve')
  );

  const uniqueReservationIds = [
    ...new Set(
      [
        ...payment_lines,
        ...merchantFee_lines,
        ...channelFee_lines,
        ...reserve_lines,
      ]
        .map((x) => x.reservationId)
        .filter(hasValue)
    ),
  ];

  const reservations = uniqueReservationIds.map<
    DepositReservationLine & Extras
  >((reservationId) => {
    const payment = payment_lines.find(
      (x) => x.reservationId === reservationId
    );
    const merchantFee = merchantFee_lines.find(
      (x) => x.reservationId === reservationId
    );

    const reserve = reserve_lines.find(
      (x) => x.reservationId === reservationId
    );

    const channelFee = channelFee_lines.find(
      (x) => x.reservationId === reservationId
    );

    const id = (payment?.id ||
      reserve?.id ||
      merchantFee?.id ||
      channelFee?.id)!;

    const transactionId = (payment?.transactionId ||
      merchantFee?.transactionId ||
      channelFee?.transactionId ||
      reserve?.transactionId)!;

    return {
      id,
      reservationId,
      uniqueRef:
        payment?.uniqueRef ||
        reserve?.uniqueRef ||
        merchantFee?.uniqueRef ||
        channelFee?.uniqueRef,
      paymentCentTotal: payment?.centTotal ?? 0,
      paymentDescription: payment?.description || '',
      reserveCentTotal: reserve?.centTotal ?? 0,
      reserveParty: reserve?.party ?? null,
      reserveDescription: reserve?.description || '',
      merchantFeeCentTotal: merchantFee?.centTotal ?? 0,
      channelFeeCentTotal: channelFee?.centTotal ?? 0,
      channelFeeDescription: channelFee?.description || '',
      merchantFeeDescription: merchantFee?.description || '',
      transactionId,
      paymentLineType: payment?.paymentLineType || null,
      reserveLineType: reserve?.paymentLineType || null,
      merchantFeeLineType: merchantFee?.paymentLineType || null,
      channelFeeLineType: channelFee?.paymentLineType || null,
    };
  });

  const manualMerchantFeeLines = lines.filter(
    (x) => !x.reservationId && x.assignment === 'deposit_merchantFee'
  );

  const manuals = lines
    .filter((x) => !x.reservationId && x.assignment !== 'deposit_merchantFee')
    .map((x) => {
      const merchantIndex = manualMerchantFeeLines.findIndex(
        (a) => x.contactId === a.contactId && x.party === a.party
      );

      const merchantFeeLine = manualMerchantFeeLines.splice(
        merchantIndex,
        1
      )[0];

      return ensure<DepositFormInputs['manuals'][0] & Extras>({
        id: x.id,
        description: x.description,
        uniqueRef: x.uniqueRef,
        listingId: x.listingId || null,
        accountId: x.accountId || null,
        party: x.party || 'manager',
        merchantFeeCentTotal: merchantFeeLine?.centTotal ?? 0,
        merchantFeeLineType: merchantFeeLine?.paymentLineType || null,
        contactId: x.contactId || null,
        transactionId: x.transactionId,
        accountAssignmentType: (x.assignment || null) as any,
        paymentCentTotal: x.centTotal,
        reservationId: x.reservationId,
        paymentLineType: x.paymentLineType,
      });
    });

  const manualLineCentTotal = getDepositManualLinesCentTotal(manuals);

  const reservationLinesCentTotal =
    getDepositReservationLinesCentTotal(reservations);

  const unassignedLines = linesWithReservation.filter((x) => !x.type);

  const unassignedLineCentTotal = sum(unassignedLines, 'centTotal');

  return {
    totals: {
      reservationLinesCentTotal,
      manualLineCentTotal,
      unassignedLineCentTotal,
      centTotal:
        manualLineCentTotal +
        reservationLinesCentTotal +
        unassignedLineCentTotal,
    },
    lines: {
      manuals,
      reservations,
      unassignedLines,
    },
  };
};
