import { useDashboard, useQuery, useTeam } from '@finalytic/data';
import { type Maybe, utc } from '@finalytic/utils';
import { whereJournalEntries } from '@vrplatform/ui-common';
import { getLedgerReservationDetail } from '../../../../../drawers/reservation-drawers/_queries/useLedgerReservationDetailDrawerQuery';

type Props = {
  reservationId: Maybe<string>;
};

export type FeeReservationPreview = NonNullable<
  ReturnType<typeof useFeeReservationPreviewQuery>['data']
>;

export const useFeeReservationPreviewQuery = (props: Props) => {
  const [{ id: teamId, partnerId }] = useTeam();
  const [dashboard] = useDashboard();

  return useQuery(
    (q, args) => {
      if (!args.id) return null;

      const reservation = getLedgerReservationDetail(q, args);

      const journalEntries = q
        .journalEntries({
          where: {
            ...whereJournalEntries({
              tenantId: args.teamId,
            }),
            reservationId: { _eq: args.id },
          },
        })
        .map((entry) => ({
          id: entry.id,
          centTotal: (entry.centTotal ?? 0) * -1,
          date: utc(entry.txnAt).yyyymmdd(),
          accountId: entry.accountId,
          transaction: {
            id: entry.transaction?.id,
            uniqueRef: entry.transaction?.uniqueRef,
            type: entry.transaction?.type,
          },
          recurringFee: {
            id: entry.recurringFee?.id,
          },
        }));

      return {
        reservation,
        journalEntries,
      };
    },
    {
      keepPreviousData: true,
      variables: {
        id: props.reservationId ?? null,
        teamId,
        partnerId,
        dashboard,
      },
    }
  );
};
