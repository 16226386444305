import { InputSelect, InputWrapper } from '@finalytic/components';
import { useMutation, useTeamId } from '@finalytic/data';
import type { accountReservationRevRec_enum } from '@finalytic/graphql';
import { Icon } from '@finalytic/icons';
import { Group, Tooltip, rem } from '@mantine/core';
import { Text } from '@mantine/core';
import { useState } from 'react';
import { useRevenueRecognitionOptions } from './LineTypeMappingEditors';
import { useDefaultRevenueRecognitionInputQuery } from './useDefaultRevenueRecognitionInputQuery';

export const DefaultRevenueRecognitionInput = () => {
  const [teamId] = useTeamId();
  const [newValue, setNewValue] = useState<
    accountReservationRevRec_enum | undefined
  >(undefined);

  const { mutate, loading: loadingMutation } = useMutation(
    (
      q,
      args: {
        id: string;
        revRec: accountReservationRevRec_enum;
      }
    ) => {
      return q.updateTenantById({
        pk_columns: {
          id: args.id,
        },
        _set: {
          defaultRevenueRecognition: args.revRec,
        },
      }).defaultRevenueRecognition;
    }
  );

  const {
    data: defaultRevenueRecognition,
    isLoading: loadingQuery,
    error,
    refetch,
  } = useDefaultRevenueRecognitionInputQuery();

  const options = useRevenueRecognitionOptions({ isTeamDefaultSelect: true });

  const value =
    options.find((o) => o.value === (newValue || defaultRevenueRecognition)) ||
    null;

  return (
    <InputWrapper
      label={
        <Tooltip
          label={
            'Choose the date on which to apply amounts. By default, the system will use the cancellation date on cancelled reservations.'
          }
          multiline
          maw={330}
        >
          <Group gap={rem(8)}>
            <Icon icon="InfoIcon" size={14} display="inline" />
            <Text fw={500}>Default Revenue Recognition:</Text>
          </Group>
        </Tooltip>
      }
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing.xs,
      })}
    >
      <InputSelect
        type="single"
        value={value}
        setValue={(value) =>
          value?.value &&
          mutate({
            args: {
              id: teamId,
              revRec: value.value,
            },
          }).then((v) => {
            setNewValue(v);
            refetch();
          })
        }
        data={{
          options,
        }}
        inputProps={{
          loadingQuery,
          error: error?.message,
          loadingMutation,
          width: 230,
          placeholder: 'Select Revenue Recognition',
        }}
      />
    </InputWrapper>
  );
};
