import { InputSelect } from '@finalytic/components';
import {
  type gqlV2,
  useInfiniteQuery,
  useInvalidateQueries,
  useMutation,
  useQuery,
  useTeamRole,
} from '@finalytic/data';
import type {
  accountAssignmentType_enum,
  accountReservationLineType_bool_exp,
  accountReservationRevRec_enum,
} from '@finalytic/graphql';
import { Icon } from '@finalytic/icons';
import {
  IconButton,
  LoadingIndicator,
  type SelectItem,
  showWarnNotification,
  useAppName,
} from '@finalytic/ui';
import { type Maybe, toTitleCase } from '@finalytic/utils';
import { Group, Text, Tooltip, useMantineColorScheme } from '@mantine/core';
import {
  formatAssignmentType,
  orderByAccount,
  whereAccounts,
} from '@vrplatform/ui-common';
import { useEffect, useMemo, useState } from 'react';
import { useAccountsConfig } from '../useAccountsConfig';
import { useDefaultRevenueRecognitionInputQuery } from './useDefaultRevenueRecognitionInputQuery';

const INPUT_WIDTH = 200;

const getLineTypeMapping = (
  mapping: gqlV2.accountReservationLineType
): LineTypeMapping => {
  return {
    id: mapping.id as string,
    value: (mapping.accountId as string | null) || 'exclude',
    label: mapping.account?.title || 'Excluded',
    revenueRecognition: mapping.revenueRecognition || null,
    bookingChannel: mapping.bookingChannel || null,
    bookingChannelExceptions: [],
  };
};

type LineTypeMapping = {
  id: string; // mapping id
  value: 'exclude' | (string & {}); // account id
  label: string; // account title
  revenueRecognition: gqlV2.accountReservationRevRec_enum | null;
  bookingChannel: string | null;
  bookingChannelExceptions?: LineTypeMapping[];
};

export const getLineTypeMappingQueryKeys = (lineType: string) => [
  'accountReservationLineTypes',
  lineType,
];

function useInvalidateLineTypeMappingTable() {
  return useInvalidateQueries(['lineTypeMappings']);
}

function useMappingMutation(lineType: string) {
  return useMutation(
    (
      q,
      args: {
        id: Maybe<string>;
        lineType: string;
        accountId: string | null;
        teamId: string;
        revenueRecognition: accountReservationRevRec_enum | null;
      }
    ) => {
      if (!args.accountId) {
        // delete mapping + exceptions
        q.deleteAccountReservationLineTypes({
          where: {
            tenantId: { _eq: args.teamId },
            lineType: { _eq: args.lineType },
          },
        }).affected_rows;

        return null;
      }

      const accountId = args.accountId === 'exclude' ? null : args.accountId;
      const revenueRecognition =
        args.accountId === 'exclude' ? null : args.revenueRecognition;

      if (args.id) {
        return getLineTypeMapping(
          q.updateAccountReservationLineType({
            pk_columns: { id: args.id },
            _set: {
              accountId,
              revenueRecognition,
              status: 'active',
            },
          })
        );
      }

      return getLineTypeMapping(
        q.insertAccountReservationLineType({
          object: {
            accountId,
            revenueRecognition,
            status: 'active',
            tenantId: args.teamId,
            lineType: args.lineType,
          },
        })
      );
    },
    {
      invalidateQueryKeys: getLineTypeMappingQueryKeys(lineType),
      invalidateExact: true,
    }
  );
}

export function useLineTypeMappingQuery(
  lineType: string,
  opts?: {
    includeExceptions?: boolean;
    skip?: boolean;
  }
) {
  const { teamId } = useAccountsConfig();

  const skip = !!opts?.skip || !lineType;

  return useQuery(
    (q, args) => {
      if (!args.lineType || args.skip) return null;

      const where: accountReservationLineType_bool_exp = {
        lineType: { _eq: args.lineType },
        tenantId: { _eq: args.teamId },
      };

      const recommendation =
        q
          .accountReservationLineTypes({
            where: {
              ...where,
              status: { _eq: 'inactive' },
              bookingChannel: { _is_null: true },
            },
            order_by: [{ updatedAt: 'desc_nulls_last' }],
            limit: 1,
          })
          .map((mapping) => getLineTypeMapping(mapping))[0] || null;

      const bookingChannelExceptions = q
        .accountReservationLineTypes({
          where: {
            ...where,
            status: { _eq: 'active' },
            bookingChannel: { _is_null: false },
          },
          order_by: [{ createdAt: 'asc_nulls_last' }],
        })
        .map((mapping) => getLineTypeMapping(mapping));

      const mapping =
        q
          .accountReservationLineTypes({
            where: {
              ...where,
              status: { _eq: 'active' },
              bookingChannel: { _is_null: true },
            },
            order_by: [{ updatedAt: 'desc_nulls_last' }],
            limit: 1,
          })
          .map((mapping) => getLineTypeMapping(mapping))[0] || null;

      return {
        mapping:
          recommendation ||
          (mapping ? { ...mapping, bookingChannelExceptions } : null),
        isRecommendation: !!recommendation,
      };
    },
    {
      skip,
      queryKey: getLineTypeMappingQueryKeys(lineType),
      variables: {
        lineType,
        teamId,
        includeExceptions: opts?.includeExceptions,
        skip,
      },
    }
  );
}

type MappingEditorProps = {
  lineType: string;
  disabled?: boolean;
};

const AccountEditor = (props: MappingEditorProps) => {
  const { isTeamAdmin } = useTeamRole();

  if (isTeamAdmin) return <AccountSelect {...props} />;

  return <AccountDisplay {...props} />;
};

const AccountAssignmentEditor = ({
  lineType,
  disabled,
}: MappingEditorProps) => {
  const { isMasterList } = useAccountsConfig();

  const skip = disabled;

  const [newValue, setNewValue] =
    useState<Maybe<SelectItem<accountAssignmentType_enum> & { id: string }>>(
      undefined
    );

  const optionsQuery = useQuery(
    (q, args) => {
      if (args.skip) return [];

      return q
        .accountAssignmentTypes({
          order_by: [
            {
              name: 'asc',
            },
          ],
          where: {
            name: { _is_null: false },
          },
        })
        .map<SelectItem<accountAssignmentType_enum>>((account) => ({
          label: toTitleCase(account.name)!,
          value: account.name as accountAssignmentType_enum,
        }));
    },
    {
      skip,
      keepPreviousData: true,
      queryKey: ['accountAssignmentTypes'],
      variables: {
        skip,
      },
    }
  );

  const valueQuery = useQuery(
    (q, args) => {
      if (args.skip) return null;

      return (
        q
          .accountAssignmentLineTypes({
            order_by: [
              {
                lineType: 'asc',
              },
            ],
            where: {
              lineType: { _eq: args.lineType },
              accountAssignmentType: { _is_null: false },
            },
          })
          .map<SelectItem<accountAssignmentType_enum> & { id: string }>(
            (x) => ({
              label: formatAssignmentType(x.accountAssignmentType)!,
              value: x.accountAssignmentType!,
              id: x.id,
            })
          )[0] || null
      );
    },
    {
      keepPreviousData: true,
      skip,
      variables: {
        lineType,
        skip,
      },
    }
  );

  const { mutate, loading: loadingMutation } = useMutation(
    (
      q,
      args: {
        id: Maybe<string>;
        lineType: string;
        accountAssignmentType: accountAssignmentType_enum | null;
      }
    ): null | (SelectItem<accountAssignmentType_enum> & { id: string }) => {
      if (!args.accountAssignmentType) {
        // delete mapping + exceptions
        q.deleteAccountAssignmentLineTypes({
          where: {
            lineType: { _eq: args.lineType },
          },
        }).affected_rows;

        return null;
      }

      const label = formatAssignmentType(args.accountAssignmentType);
      const value = args.accountAssignmentType;

      if (args.id) {
        q.updateAccountAssignmentLineType({
          pk_columns: { id: args.id },
          _set: {
            accountAssignmentType: args.accountAssignmentType,
          },
        })?.id;

        return {
          label,
          value,
          id: args.id,
        };
      }

      return {
        label,
        value,
        id: q.insertAccountAssignmentLineType({
          object: {
            accountAssignmentType: args.accountAssignmentType,
            lineType: args.lineType,
          },
        })?.id,
      };
    },
    {
      invalidateQueryKeys: getLineTypeMappingQueryKeys(lineType),
      invalidateExact: true,
    }
  );

  const value = disabled
    ? null
    : newValue !== undefined
      ? newValue
      : valueQuery.data || null;

  if (!isMasterList) return null;

  return (
    <InputSelect
      type="single"
      value={value}
      setValue={(newValue) => {
        mutate({
          args: {
            accountAssignmentType: newValue?.value || null,
            lineType: lineType!,
            id: valueQuery.data?.id,
          },
        }).then(setNewValue);
      }}
      data={{
        options: optionsQuery.data || [],
        error: optionsQuery.error,
        loading: optionsQuery.isLoading,
      }}
      inputProps={{
        width: INPUT_WIDTH,
        placeholder: disabled ? '-' : 'Select assignment',
        error: !!valueQuery.error,
        loadingMutation,
        loadingQuery: valueQuery.isLoading,
        withClearButton: true,
        disabled,
      }}
      dropdownProps={{
        withinPortal: true,
      }}
    />
  );
};

const AccountDisplay = (props: MappingEditorProps) => {
  const { data, isLoading } = useLineTypeMappingQuery(props.lineType);

  if (!data && isLoading) return <LoadingIndicator size="xs" />;

  return data?.mapping?.label || '-';
};

const AccountSelect = ({ lineType }: MappingEditorProps) => {
  const { teamId } = useAccountsConfig();
  const [search, setSearch] = useState('');

  const invalidateTable = useInvalidateLineTypeMappingTable();

  const [newValue, setNewValue] = useState<LineTypeMapping | null | undefined>(
    undefined
  );

  const queryData = useInfiniteQuery(
    (q, args, { limit, offset }) => {
      const where = whereAccounts({
        search: args.search,
        tenantId: args.teamId,
      });

      const aggregate =
        q
          .accountAggregate({
            where,
          })
          ?.aggregate?.count() || 0;

      const list = q
        .accounts({
          where,
          order_by: orderByAccount,
          limit,
          offset,
        })
        .map<SelectItem>((account) => {
          const assignments = account
            .assignments({
              where: {
                type: { _in: ['accountsPayable', 'accountsReceivable'] },
              },
            })
            .map((a) => formatAssignmentType(a.type));

          const disabled = !!assignments.length;

          const disabledDescription =
            assignments.length === 1
              ? `This account is assigned to ${assignments[0]}`
              : `This account is assigned to ${assignments.join(' & ')}`;

          return {
            label: account.title || 'No name',
            value: account.id,
            group: toTitleCase(account.classification || 'No classification'),
            disabled,
            description: disabled ? disabledDescription : undefined,
          };
        });

      return {
        aggregate,
        list,
      };
    },
    {
      queryKey: ['accounts'],
      variables: {
        search: search?.trim(),
        teamId,
      },
    }
  );

  const {
    mutate,
    loading: loadingMutation,
    error,
  } = useMappingMutation(lineType);
  const {
    isLoading: loadingQuery,
    data,
    refetch,
  } = useLineTypeMappingQuery(lineType);

  const value = newValue !== undefined ? newValue : data?.mapping || null;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (newValue !== undefined) setNewValue(undefined);
  }, [teamId]);

  if (data?.isRecommendation && data.mapping && !newValue) {
    const onAccept = () => {
      mutate({
        args: {
          accountId: value?.value || null,
          lineType,
          teamId,
          id: value?.id,
          revenueRecognition: value?.revenueRecognition || null,
        },
      }).then(() => refetch());
    };

    const onDecline = () => {
      mutate({
        args: {
          accountId: null, // empty => delete
          lineType,
          teamId,
          id: value?.id,
          revenueRecognition: value?.revenueRecognition || null,
        },
      }).then(() => refetch());
    };

    return (
      <RecommendationInput
        loadingMutation={loadingMutation}
        loadingQuery={loadingQuery}
        onAccept={onAccept}
        onDecline={onDecline}
        label={data.mapping.label || ''}
      />
    );
  }

  return (
    <InputSelect
      type="single"
      value={value}
      setValue={(newValue) => {
        mutate({
          args: {
            accountId: newValue?.value || null,
            lineType: lineType,
            teamId,
            id: value?.id,
            revenueRecognition: value?.revenueRecognition || null,
          },
        }).then((v) => {
          setNewValue(v);

          // if deleted or added new from scratch, also refetch recognition date
          if (!v || (newValue?.value && !value)) refetch({});

          // refetch whole table on deletion in case of exceptions rows
          if (!newValue?.value) invalidateTable();
        });
      }}
      infiniteData={{ ...queryData, setSearch }}
      inputProps={{
        width: INPUT_WIDTH,
        placeholder: 'Select account',
        error: !!error,
        loadingMutation,
        loadingQuery: loadingQuery,
        withClearButton: true,
      }}
      dropdownProps={{
        withinPortal: true,
      }}
      pinnedItems={[
        {
          label: 'Exclude mapping',
          value: 'exclude',
          description: 'Exclude this line type from the system.',
        },
      ]}
    />
  );
};

const RecognitionDateEditor = (props: MappingEditorProps) => {
  const { isTeamAdmin } = useTeamRole();

  if (isTeamAdmin) return <RecognitionRecognitionSelect {...props} />;

  return <RecognitionRecognitionDisplay {...props} />;
};

export function useRevenueRecognitionOptions(args?: {
  isTeamDefaultSelect: boolean;
}) {
  return useMemo(() => {
    const opts: SelectItem<accountReservationRevRec_enum>[] = [
      {
        label: 'Pro Rata',
        value: 'proRata',
        // group: 'Reservation',
        description:
          'Revenue is split into equal nightly amounts across the entire length of the stay',
      },
      {
        label: 'Booked At',
        value: 'bookedAt',
        // group: 'Reservation',
        description: 'The date on which the reservation was booked',
      },
      {
        label: 'Check-in',
        value: 'checkIn',
        // group: 'Reservation',
        description: 'The Check-in date of the reservation',
      },
      {
        label: 'Check-out',
        value: 'checkOut',
        // group: 'Reservation',
        description: 'The Check-out date of the reservation',
      },
    ];

    if (args?.isTeamDefaultSelect) {
      return opts.filter((x) => x.value !== 'proRata');
    }

    return opts;

    // const reservation_options: SelectItem[] = [
    //   { label: 'Pro Rata', value: 'proRata', group: 'Reservation' },
    //   { label: 'Booked At', value: 'bookedAt', group: 'Reservation' },
    //   { label: 'Check-in Date', value: 'checkIn', group: 'Reservation' },
    //   { label: 'Check-out Date', value: 'checkOut', group: 'Reservation' },
    //   {
    //     label: 'Cancellation Date (or booked at)',
    //     value: 'cancelledAt',
    //     group: 'Reservation',
    //   },
    // ];
    // const payment_options: SelectItem[] = [
    //   { label: 'Paid At', value: 'payedAt', group: 'Payment' },
    //   {
    //     label: 'Cleared At (or paid at)',
    //     value: 'clearedAt',
    //     group: 'Payment',
    //   },
    // ];

    // return [
    //   ...payment_options,
    //   ...reservation_options,
    // ];
  }, [args?.isTeamDefaultSelect]);
}

const RecognitionRecognitionDisplay = (props: MappingEditorProps) => {
  const { data, isLoading } = useLineTypeMappingQuery(props.lineType, {
    skip: props.disabled,
  });

  const options = useRevenueRecognitionOptions();

  const label = useMemo(() => {
    return options.find((x) => x.value === data?.mapping?.revenueRecognition)
      ?.label;
  }, [data?.mapping?.revenueRecognition, options]);

  if (!data && isLoading) return <LoadingIndicator size="xs" />;

  return label || '-';
};

const RecognitionRecognitionSelect = ({
  lineType,
  disabled: inputDisabled,
}: MappingEditorProps) => {
  const { teamId } = useAccountsConfig();
  const [newValue, setNewValue] = useState<LineTypeMapping | null | undefined>(
    undefined
  );

  const invalidateTable = useInvalidateLineTypeMappingTable();

  const options = useRevenueRecognitionOptions();

  const {
    mutate,
    loading: loadingMutation,
    error,
  } = useMappingMutation(lineType);

  const {
    isLoading: loadingQuery1,
    data,
    refetch,
  } = useLineTypeMappingQuery(lineType, { skip: inputDisabled });

  const { data: defaultRevenueRecognition, isLoading: loadingQuery2 } =
    useDefaultRevenueRecognitionInputQuery();

  const loadingQuery = loadingQuery1 || loadingQuery2;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const value = useMemo(() => {
    if (!data?.mapping?.value) return null;

    if (newValue !== undefined) return newValue;

    const label = options.find(
      (x) => x.value === data?.mapping?.revenueRecognition
    )?.label;

    return data?.mapping?.revenueRecognition && data?.mapping?.value
      ? {
          ...data.mapping,
          value: data.mapping.revenueRecognition,
          label: label || data.mapping.revenueRecognition || '',
        }
      : null;
  }, [
    newValue?.value,
    JSON.stringify(options),
    data?.mapping?.revenueRecognition,
    data?.mapping?.value,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (newValue !== undefined) setNewValue(undefined);
  }, [teamId]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setNewValue(undefined);
  }, [data?.mapping?.value]);

  if (data?.isRecommendation && data.mapping && !newValue) {
    const onAccept = () => {
      mutate({
        args: {
          accountId: data.mapping?.value,
          lineType,
          teamId,
          id: value?.id,
          revenueRecognition: value?.revenueRecognition || null,
        },
      }).then(() => refetch());
    };

    const onDecline = () => {
      mutate({
        args: {
          accountId: null, // empty => delete
          lineType,
          teamId,
          id: value?.id,
          revenueRecognition: value?.revenueRecognition || null,
        },
      }).then(() => refetch());
    };

    return (
      <RecommendationInput
        loadingMutation={loadingMutation}
        loadingQuery={loadingQuery}
        onAccept={onAccept}
        onDecline={onDecline}
        label={value?.label || 'Missing recommendation'}
      />
    );
  }

  const disabled =
    inputDisabled || !data?.mapping?.value || data.mapping.value === 'exclude';

  return (
    <InputSelect
      type="single"
      value={value}
      setValue={(newValue) => {
        const accountId = data?.mapping.value;

        if (!accountId)
          return showWarnNotification({
            label: 'Missing account mapping',
            message:
              'Please select an account first before changing the recognition date',
          });

        mutate({
          args: {
            accountId,
            lineType,
            teamId,
            id: data.mapping?.id,
            revenueRecognition: (newValue?.value as any) || null,
          },
        }).then((returned) => {
          if (returned?.revenueRecognition) {
            const label = options.find(
              (x) => x.value === returned?.revenueRecognition
            )?.label;
            setNewValue({
              ...returned,
              value: returned.revenueRecognition!,
              label: label || returned?.revenueRecognition || '',
            });
            if (!value) refetch();
          } else {
            setNewValue(null);

            // refetch whole table on deletion in case of exceptions rows
            invalidateTable();
            refetch();
          }
        });
      }}
      data={{
        options,
      }}
      inputProps={{
        width: INPUT_WIDTH,
        placeholder: disabled
          ? '-'
          : (options.find((x) => x.value === defaultRevenueRecognition)
              ?.label ?? '-'),
        error: !!error,
        loadingMutation,
        loadingQuery: loadingQuery,
        withClearButton: true,
        disabled,
      }}
      dropdownProps={{
        withinPortal: true,
      }}
    />
  );
};

export const LineTypeMappingEditors = {
  Account: AccountEditor,
  AccountAssignment: AccountAssignmentEditor,
  RecognitionRecognition: RecognitionDateEditor,
};

const RecommendationInput = ({
  loadingMutation,
  loadingQuery,
  onAccept,
  onDecline,
  label,
}: {
  loadingMutation: boolean;
  loadingQuery: boolean;
  onAccept: () => void;
  onDecline: () => void;
  label: string;
}) => {
  const { appName } = useAppName();
  const { colorScheme } = useMantineColorScheme();

  return (
    <>
      <Group
        wrap="nowrap"
        justify="space-between"
        id="input-target"
        mr="sm"
        sx={(theme) => {
          const isDarkTheme = colorScheme === 'dark';

          return {
            width: INPUT_WIDTH,
            border: '1px solid',
            borderColor: theme.colors.orange[isDarkTheme ? 8 : 4],
            borderRadius: theme.radius.md,
            overflow: 'hidden',
            padding: `2px ${theme.spacing.sm}`,
            minHeight: '2.25rem',
            backgroundColor: isDarkTheme ? theme.colors.gray[9] : theme.white,
            boxShadow: `0px 0px 0px 2px ${theme.colors.orange[4]}40`,
          };
        }}
      >
        <Text>{label}</Text>
        {loadingMutation || loadingQuery ? (
          <LoadingIndicator size="xs" color="orange" />
        ) : (
          <Group wrap="nowrap" gap={5}>
            {/* ACCEPT */}
            <IconButton onClick={onAccept}>
              <Icon
                icon="CheckIcon"
                size={20}
                color={(theme) => theme.colors.green[6]}
              />
            </IconButton>
            {/* DECLINE */}
            <IconButton onClick={onDecline}>
              <Icon
                icon="CrossIcon"
                size={20}
                color={(theme) => theme.colors.red[6]}
              />
            </IconButton>
          </Group>
        )}
      </Group>
      <Tooltip
        withArrow
        withinPortal
        label={`Recommended mapping by ${appName}`}
      >
        <Icon
          icon="InfoIcon"
          size={20}
          color={(theme) => theme.colors.orange[4]}
        />
      </Tooltip>
    </>
  );
};
