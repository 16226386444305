import { Button } from '@finalytic/components';
import {
  captureSentryError,
  type gqlV2,
  useGqtyClient,
  useTeamId,
} from '@finalytic/data';
import type { recurringFeeType_enum } from '@finalytic/graphql';
import { Icon } from '@finalytic/icons';
import { showWarnNotification, useAppName } from '@finalytic/ui';
import { ensure } from '@finalytic/utils';
import { Center, Menu } from '@mantine/core';
import { type ReactNode, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import type { FeeFormInputs } from '../../edit/useFeeForm';
import { FEE_TYPES } from './FeeTypeCarousel';

export const AddFeeButton = ({
  children = 'Add fee',
  width,
}: { children?: ReactNode; width?: number }) => {
  const { loading, mutate } = useAddRecurringFee();

  const goToAdd = useCallback(
    (template: Template) => mutate(template),
    [mutate]
  );

  return (
    <>
      <Menu trigger="hover" shadow="md" position="bottom-end" withArrow>
        <Menu.Target>
          <Button
            variant="primary"
            leftIcon={'PlusIcon'}
            loading={loading}
            sx={{
              width,
            }}
          >
            {children}
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          {Object.entries(FEE_TYPES).map(([key, value]) => {
            return (
              <Menu.Item
                key={key}
                leftSection={
                  <Center
                    w={20}
                    sx={{
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Icon
                      icon={value.icon}
                      // color={(theme) => theme.colors[theme.primaryColor][6]}
                      size={18}
                    />
                  </Center>
                }
                onClick={() => {
                  const fee = Object.entries(FEE_TEMPLATES).find(
                    ([, v]) => value.type === v.formInputs.type
                  );
                  if (fee) {
                    goToAdd(fee[0] as Template);
                  } else {
                    showWarnNotification({
                      title: 'Fee type not found',
                      message: `The fee type ${key} was not found in the template list.`,
                    });
                  }
                }}
              >
                {value.title}
              </Menu.Item>
            );
          })}
        </Menu.Dropdown>
      </Menu>
    </>
  );
};

type Template = recurringFeeType_enum;

export const FEE_TEMPLATES: Record<
  recurringFeeType_enum,
  {
    formInputs: FeeFormInputs;
    config: {
      accounts: string[];
      formulaTemplate: string;
    };
  }
> = {
  managementFee: {
    formInputs: {
      // icon: 'PercentageIcon',
      creditAccountId: '4160',
      debitAccountId: '5000',
      // expression: '',
      // expressionUnformatted: '',
      creditParty: 'manager',
      debitParty: 'owners',
      // rateType: 'percentage',
      // formulaId: undefined,
      id: undefined,
      title: 'Management Commission',
      // trigger: 'reservation',
      revenueRecognition: 'checkIn',
      type: 'managementFee',
      bookingChannelsFilter: 'all-channels',
      statusFilter: null,
      taxRateId: null,
      taxRateBehavior: 'excluded',
      defaultRateBasePoints: 200,
      linkedAccountIds: [],
    },
    config: {
      accounts: ['4000', '4020', '5010', '5020'],
      formulaTemplate: `(accounts) * "rate"`,
    },
  },
  merchantFee: {
    // STRIPE
    formInputs: {
      // icon: 'StripeIcon',
      creditAccountId: '5020',
      debitAccountId: '5020',
      // expression: '',
      // expressionUnformatted: '',
      creditParty: 'manager',
      debitParty: 'owners',
      // rateType: 'flat',
      // formulaId: undefined,
      id: undefined,
      title: 'Merchant Fees',
      // trigger: 'reservation',
      revenueRecognition: 'checkIn',
      type: 'merchantFee',
      bookingChannelsFilter: [],
      statusFilter: null,
      taxRateId: null,
      taxRateBehavior: 'excluded',
      defaultRateBasePoints: 25,
      linkedAccountIds: [],
    },
    config: {
      accounts: ['5020'],
      formulaTemplate: '-(accounts)',
    },
  },
  bookingChannelFee: {
    // BOOKING.COM
    formInputs: {
      // icon: 'BookingComIcon',
      creditAccountId: '5010',
      debitAccountId: '5010',
      // expression: '',
      // expressionUnformatted: '',
      creditParty: 'manager',
      debitParty: 'owners',
      // rateType: 'percentage',
      // formulaId: undefined,
      id: undefined,
      title: 'Booking Channel Fee',
      // trigger: 'reservation',
      revenueRecognition: 'checkIn',
      type: 'bookingChannelFee',
      bookingChannelsFilter: [],
      statusFilter: null,
      taxRateId: null,
      taxRateBehavior: 'excluded',
      defaultRateBasePoints: 25,
      linkedAccountIds: [],
    },
    config: {
      accounts: ['4000', '4010'],
      formulaTemplate: `(accounts) * "rate"`,
    },
  },
  cleaningFee: {
    formInputs: {
      // icon: 'BedIcon',
      creditAccountId: '4010',
      debitAccountId: '4010',
      // expression: '',
      // expressionUnformatted: '',
      creditParty: 'manager',
      debitParty: 'owners',
      // rateType: 'flat',
      // formulaId: undefined,
      id: undefined,
      title: 'Cleaning Fee',
      // trigger: 'reservation',
      revenueRecognition: 'checkIn',
      type: 'cleaningFee',
      bookingChannelsFilter: 'all-channels',
      statusFilter: null,
      taxRateId: null,
      taxRateBehavior: 'excluded',
      defaultRateBasePoints: 100,
      linkedAccountIds: [],
    },
    config: {
      accounts: ['4010'],
      formulaTemplate: 'accounts',
    },
  },
  // vrbo: {
  //   formInputs: {
  //     // icon: 'OfficeIcon',
  //     creditAccountId: '5010',
  //     debitAccountId: '5010',
  //     // expression: '',
  //     // expressionUnformatted: '',
  //     creditParty: 'manager',
  //     debitParty: 'owners',
  //     // rateType: 'flat',
  //     // formulaId: undefined,
  //     id: undefined,
  //     title: 'VRBO Channel Fees',
  //     // trigger: 'reservation',
  //     triggerValue: 'checkIn',
  //     type: 'bookingChannelFee',
  //     bookingChannels: [],
  //     taxRateId: null,
  //     taxRateBehavior: 'excluded',
  //     defaultRate: 0.1,
  //     linkedAccountIds: [],
  //   },

  //   config: {
  //     accounts: ['4000', '4010'],
  //     formulaTemplate: `(accounts) * "rate"`,
  //   },
  // },
  additionalFee: {
    formInputs: {
      // icon: 'PercentageIcon',
      creditAccountId: 'create_new',
      debitAccountId: 'create_new',
      // expression: '',
      // expressionUnformatted: '',
      creditParty: 'manager',
      debitParty: 'owners',
      // rateType: 'percentage',
      // formulaId: undefined,
      id: undefined,
      title: 'Custom Fee',
      // trigger: 'reservation',
      revenueRecognition: 'checkIn',
      type: 'additionalFee',
      bookingChannelsFilter: 'all-channels',
      statusFilter: null,
      taxRateId: null,
      taxRateBehavior: 'excluded',
      defaultRateBasePoints: 25,
      linkedAccountIds: [],
    },
    config: {
      accounts: [],
      formulaTemplate: '(accounts) * "rate"',
    },
  },
};

const useAddRecurringFee = () => {
  const goto = useNavigate();
  const [teamId] = useTeamId();
  const [loading, setLoading] = useState(false);
  const { appName } = useAppName();

  const client = useGqtyClient();

  const mutate = useCallback(
    async (templateKey: Template) => {
      try {
        setLoading(true);

        const template = FEE_TEMPLATES[templateKey];

        if (!template) throw new Error('Template not found');

        // get more info
        const queryData = await client.query((q) => {
          const getAccount = (account: gqlV2.account) => ({
            id: account.id,
            label: account.title || 'No name',
            uniqueRef: account.uniqueRef,
          });

          let debitCreditAccounts: ReturnType<typeof getAccount>[] = [];

          const formulaAccounts = q
            .accounts({
              where: {
                tenantId: { _eq: teamId },
                uniqueRef: { _in: template.config.accounts },
              },
              order_by: [{ uniqueRef: 'asc_nulls_last' }],
            })
            .map(getAccount);

          const debitCreditAccountIds = [
            template.formInputs.debitAccountId,
            template.formInputs.creditAccountId,
          ].filter((x) => !Number.isNaN(Number(x)));

          if (debitCreditAccountIds.length) {
            debitCreditAccounts = q
              .accounts({
                where: {
                  tenantId: { _eq: teamId },
                  uniqueRef: { _in: debitCreditAccountIds },
                },
                order_by: [{ uniqueRef: 'asc_nulls_last' }],
              })
              .map(getAccount);
          }

          const defaultRecognition =
            q.tenantById({
              id: teamId,
            })?.defaultRevenueRecognition || 'checkIn';

          return {
            formulaAccounts,
            debitCreditAccounts,
            defaultRecognition,
          };
        });

        if (
          queryData.formulaAccounts.length !== template.config.accounts.length
        ) {
          const missingAccounts = template.config.accounts.filter(
            (account) =>
              !queryData.formulaAccounts.find((a) => a.uniqueRef === account)
          );

          showWarnNotification({
            title: 'Missing template accounts',
            message: `The following accounts are missing from your team for the ${appName} default template: ${missingAccounts.join(', ')}`,
          });
        }

        goto('/fees-commissions/add', {
          state: ensure<FeeFormInputs>({
            ...template.formInputs,
            linkedAccountIds: queryData.formulaAccounts.map((a) => a.id),
            creditAccountId:
              queryData.debitCreditAccounts.find(
                (x) => x.uniqueRef === template.formInputs.creditAccountId
              )?.id || 'create_new',
            debitAccountId:
              queryData.debitCreditAccounts.find(
                (x) => x.uniqueRef === template.formInputs.debitAccountId
              )?.id || 'create_new',
            revenueRecognition: queryData.defaultRecognition,
          }),
        });
      } catch (error: any) {
        captureSentryError(error);
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [goto, teamId, client, appName]
  );

  return {
    mutate,
    loading,
  };
};
