import type {
  reservation as Reservation,
  recurringFeeType_enum,
} from '@finalytic/graphql';
import { sortBy } from '@finalytic/utils';
import { queryReservationFinancials } from '@vrplatform/ui-common';

type Props = {
  partnerId: string;
  tenantId: string;
};

export const getLedgerReservationFinancials = (
  reservation: Reservation,
  { partnerId, tenantId }: Props
) => {
  const status = reservation.status;
  const paidStatus = reservation.paidStatus;

  const isCancelledAndPending = status === 'cancelled' && paidStatus !== 'paid';

  const initialFinancials = sortBy(
    queryReservationFinancials(reservation, {
      partnerId,
      tenantId,
      GL: true,
    }),
    (x) =>
      x.type === 'unclassified' ? `a-${x.description}` : `b-${x.description}`
  );

  const ownerFinancials = initialFinancials.filter((x) => x.party === 'owners');

  const includedFinancials = ownerFinancials.filter(
    (x) => x.isInvoice === 'invoice'
  );

  const paidCentTotal = reservation.centPaid ?? 0;
  const centTotal = reservation.centTotal ?? 0;
  const unpaidCentTotal = centTotal - paidCentTotal;

  type RecurringFee = {
    id: string;
    recurringFee:
      | { id: string; type: recurringFeeType_enum; title: string }
      | undefined;
    title: string;
    centTotal: number;
    currency: string;
    isAdjustment: boolean;
  };

  const recurringFeesJournalEntries = reservation
    .journalEntries({
      where: {
        recurringFee: {
          tenantId: { _eq: tenantId },
        },
        party: { _eq: 'manager' },
      },
      order_by: [
        {
          createdAt: 'asc_nulls_last',
        },
      ],
    })
    .map((journalEntry) => {
      return {
        id: journalEntry.id,
        recurringFee: {
          id: journalEntry.recurringFeeId!,
          type: journalEntry.recurringFee?.type ?? 'additionalFee',
          title: journalEntry.recurringFee?.title || 'No name',
        },
        centTotal: (journalEntry.centTotal ?? 0) * -1,
        currency: journalEntry.currency || 'usd',
        party: journalEntry.party,
        isAdjustment: journalEntry.uniqueRef?.startsWith('adjustment_'),
      };
    });

  const recurringFees = recurringFeesJournalEntries.reduce<RecurringFee[]>(
    (acc, journalEntry) => {
      if (journalEntry.isAdjustment) return acc;

      const index = acc.findIndex((x) => x.id === journalEntry.recurringFee.id);

      const journalEntryCentTotal = journalEntry.centTotal;

      if (index === -1)
        acc.push({
          id: journalEntry.recurringFee.id,
          recurringFee: journalEntry.recurringFee,
          centTotal: journalEntryCentTotal,
          currency: journalEntry.currency,
          title: journalEntry.recurringFee.title,
          isAdjustment: false,
        });
      else acc[index].centTotal += journalEntryCentTotal;
      return acc;
    },
    []
  );

  const taxCentTotal = includedFinancials.reduce((acc, curr) => {
    const isTax = curr.description?.includes('tax');
    const centTotal = curr.centTotal;

    if (status === 'cancelled') {
      return acc + (isTax && curr.type === 'manual' ? centTotal : 0);
    }

    if (isTax) {
      return acc + centTotal;
    }

    return acc;
  }, 0);

  const pmFinancials = initialFinancials
    .filter((x) => x.party === 'manager')
    .map<RecurringFee>((line) => {
      return {
        id: line.id,
        recurringFee: {
          id: line.recurringFeeId!, // DUMMY
          type: 'additionalFee',
          title: line.description ?? '',
        },
        centTotal: line.centTotal,
        currency: line.currency ?? 'usd',
        title: line.description ?? '',
        isAdjustment: true,
      };
    });

  const feesAndCommissions: RecurringFee[] = [
    ...recurringFees,
    ...pmFinancials,
  ];

  const pmRevenueCentTotal = feesAndCommissions.reduce(
    (acc, curr) => acc + curr.centTotal,
    0
  );

  const ownerRevenueCentTotal = centTotal - pmRevenueCentTotal - taxCentTotal;

  return {
    centTotals: {
      paidCentTotal,
      centTotal,
      unpaidCentTotal,
      taxCentTotal: isCancelledAndPending ? 0 : taxCentTotal,
      pmRevenueCentTotal,
      ownerRevenueCentTotal: isCancelledAndPending ? 0 : ownerRevenueCentTotal,
    },
    feesAndCommissions,
    recurringFeesJournalEntries,
    financials: ownerFinancials,
    currency: reservation.currency,
    isCancelledAndPending,
  };
};
