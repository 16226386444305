import {
  Button,
  IconButton,
  Input,
  InputWrapper,
  Select,
} from '@finalytic/components';
import type { TRIGGER_VARS } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import {
  showErrorNotification,
  showSuccessNotification,
  useAppName,
} from '@finalytic/ui';
import { sortBy } from '@finalytic/utils';
import {
  Box,
  Center,
  Group,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { useMemo } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { SettingsTitle, SettingsViewContainer } from './_components';

export const SettingsDeveloperView = () => {
  const { appName } = useAppName();

  return (
    <SettingsViewContainer maxWidth={600}>
      <SettingsTitle type="view-title">Development</SettingsTitle>
      <Text
        component="p"
        size={'0.875rem'}
        mb="md"
        sx={(theme) => ({ color: theme.colors.gray[7] })}
      >
        Manage your developer settings on {appName}.
      </Text>
      <DevKeySettings />
    </SettingsViewContainer>
  );
};

type DevKeys = Partial<TRIGGER_VARS>;

type FormInputs = {
  keys: {
    key: keyof TRIGGER_VARS;
    value: string;
  }[];
};

const DevKeySettings = () => {
  const [keys, setKeys] = useLocalStorage<DevKeys>({
    key: 'devKeys',
    defaultValue: {},
  });

  const initial = sortBy(
    Object.entries(keys).filter((x) => x[1]),
    (x) => x[0]
  ).map(([key, value]) => ({
    key: key as keyof TRIGGER_VARS,
    value: value!,
  }));

  const methods = useForm<FormInputs>({
    values: {
      keys: initial,
    },
    shouldUnregister: true,
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'keys',
    shouldUnregister: true,
  });

  const submit = (data: FormInputs) => {
    const keys = data.keys.filter((x) => x.value).map((x) => x.key);

    // has keys twice or more
    if (keys.length !== new Set(keys).size) {
      return showErrorNotification({
        title: 'Duplicate keys',
        message: 'Please remove duplicate keys.',
      });
    }

    const newKeys = data.keys.reduce<DevKeys>((acc, key) => {
      if (key.value?.trim()) acc[key.key] = key.value?.trim();
      return acc;
    }, {});

    setKeys(newKeys);

    showSuccessNotification({
      title: 'Success!',
      message: 'Your dev keys have been saved.',
    });
  };

  return (
    <FormProvider {...methods}>
      <InputWrapper
        label="Trigger Dev Keys"
        description="Manage your trigger dev environment keys"
      >
        <Stack mt="xs">
          {!fields.length && (
            <Center
              sx={{
                flexDirection: 'column',
                gap: 10,
                marginTop: 20,
              }}
            >
              <Icon icon="GearIcon" size={18} />
              <Text c="gray">No keys added yet</Text>
              <AddKeyButton
                variant="primary"
                append={(newValue) => {
                  append(
                    {
                      key: newValue,
                      value: '',
                    },
                    {
                      shouldFocus: true,
                    }
                  );
                }}
              />
            </Center>
          )}

          {fields.map((field, index) => {
            return (
              <Group wrap="nowrap" align="flex-start" key={field.id}>
                <Controller
                  name={`keys.${index}.key`}
                  control={methods.control}
                  render={({ field: { value } }) => (
                    <>
                      <Text mt={8}>{value}</Text>
                    </>
                  )}
                />

                <Controller
                  name={`keys.${index}.value`}
                  control={methods.control}
                  rules={{
                    validate: (value) =>
                      !!value.trim() || 'Please enter a value',
                  }}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <InputWrapper ml="auto" error={fieldState.error?.message}>
                      <Input
                        w={250}
                        value={value}
                        onChange={onChange}
                        debounce={0}
                        placeholder="Enter dev key"
                        error={fieldState.error?.message}
                      />
                    </InputWrapper>
                  )}
                />

                <IconButton
                  icon="TrashIcon"
                  size={18}
                  onClick={() => {
                    remove(index);
                  }}
                />
              </Group>
            );
          })}

          {!!fields.length && (
            <>
              <AddKeyButton
                variant="light"
                append={(newValue) => {
                  append(
                    {
                      key: newValue,
                      value: '',
                    },
                    {
                      shouldFocus: true,
                    }
                  );
                }}
              />
              <Group ml="auto">
                <Button
                  type="reset"
                  disabled={methods.formState.isSubmitting}
                  onClick={() => methods.reset({ keys: initial })}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  onClick={methods.handleSubmit(submit)}
                  loading={methods.formState.isSubmitting}
                >
                  Save changes
                </Button>
              </Group>
            </>
          )}
        </Stack>
      </InputWrapper>
    </FormProvider>
  );
};

const AddKeyButton = ({
  append,
  variant,
}: {
  append: (key: keyof TRIGGER_VARS) => void;
  variant: 'primary' | 'light';
}) => {
  const { primaryColor } = useMantineTheme();
  const methods = useFormContext<FormInputs>();

  const existing = methods.watch('keys').map((x) => x.key);

  const options = useMemo(() => {
    const base: TRIGGER_VARS = {
      IMPORT_CSV_TRIGGER_SECRET_KEY: '',
      NOTIFICATIONS_TRIGGER_SECRET_KEY: '',
      TRIGGER_SECRET_KEY: '',
      API_TRIGGER_SECRET_KEY: '',
    };

    return Object.keys(base)
      .filter((key) => !existing.includes(key as keyof TRIGGER_VARS))
      .map((x) => ({
        label: x,
        value: x,
      }));
  }, [existing]);

  return (
    <Box ml={variant === 'primary' ? undefined : 'auto'}>
      <Select
        type="single"
        value={null}
        data={{
          options,
        }}
        dropdownProps={{
          width: 400,
          position: 'bottom-end',
          hideSearch: true,
        }}
        inputProps={{
          disabled: !options.length,
        }}
        setValue={(value) => {
          if (!value?.value) return;
          append(value.value as keyof TRIGGER_VARS);
        }}
      >
        {({ disabled }) => {
          return (
            <Button
              variant={variant}
              disabled={disabled}
              leftIcon="PlusIcon"
              color={variant === 'primary' ? undefined : primaryColor}
              size="xs"
              sx={{
                marginLeft: 'auto',
              }}
            >
              Add key
            </Button>
          );
        }}
      </Select>
    </Box>
  );
};
