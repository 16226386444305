import { useAuth } from '@clerk/clerk-react';
import { useMe, useTeamId, useTrpcMutation } from '@finalytic/data';
import { UserIcon } from '@finalytic/icons';
import { showErrorNotification } from '@finalytic/ui';
import { Notification } from '@mantine/core';
import { Global } from '@mantine/emotion';
import { formatUserName } from '@vrplatform/ui-common';

export const OwnerImpersonationNotification = () => {
  const [teamId] = useTeamId();
  const { actor, signOut } = useAuth();
  const { firstName, lastName, companyName } = useMe();

  const { loading, mutate } = useTrpcMutation('resetImpersonation');

  const resetPreview = async () => {
    if (!actor?.sub)
      return showErrorNotification({
        message: 'Missing actor id.',
        color: 'yellow',
      });

    const res = await mutate({
      actorClerkUserId: actor?.sub,
      tenantId: teamId,
    });

    signOut({
      redirectUrl: res.url,
    });
  };

  if (!actor?.sub) return null;

  return (
    <>
      <Notification
        radius={10}
        icon={<UserIcon color="white" size={16} />}
        color="dark"
        loading={loading}
        title="Owner Impersonation"
        onClose={resetPreview}
        withBorder
        styles={(theme) => ({
          root: {
            position: 'absolute',
            transition: 'left 0.3s ease-out',
            top: theme.spacing.sm,
            left: '50%',
            transform: 'translateX(-50%)',
          },
        })}
      >
        You are previewing as{' '}
        <b>{formatUserName({ firstName, lastName, companyName })}</b>.
      </Notification>
      <Global
        styles={() => ({
          '.intercom-lightweight-app, .cl-impersonationFab': {
            display: 'none',
          },
        })}
      />
    </>
  );
};
