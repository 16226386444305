import { Filter } from '@finalytic/components';
import { useQuery, useTeamId } from '@finalytic/data';
import { LazyTable, type MRT_ColumnDef } from '@finalytic/table';
import { StringParam, useQueryParams } from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import {
  Alert,
  Box,
  Center,
  Group,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { Text } from '@mantine/core';
import { whereAccounts } from '@vrplatform/ui-common';
import { Fragment, useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useFeeForm } from '../useFeeForm';

type Props = {
  initialAccountIds: string[];
};

export function useFeeAccountsQuery(props: Props) {
  const [teamId] = useTeamId();

  const queryData = useQuery(
    (q, args) => {
      const accounts = q
        .accounts({
          where: whereAccounts({
            tenantId: args.teamId,
          }),
          order_by: [{ title: 'asc_nulls_last' }],
        })
        .map((account) => ({
          id: account.id,
          title: account.title || '-',
          lineItems: account
            .reservationLineTypes({
              where: {
                tenantId: { _eq: args.teamId },
                status: { _eq: 'active' },
                lineType: { _is_null: false },
              },
              order_by: [{ lineType: 'asc_nulls_last' }],
            })
            .map((mapping) => {
              const lineType = mapping.lineType || '';
              const hasApp = lineType.includes('_');
              const app = hasApp ? lineType.split('_')[0] : null;
              const line = toTitleCase(
                hasApp ? lineType.split('_')[1] : lineType
              );

              return {
                lineType: lineType!,
                app,
                line,
              };
            }),
        }));

      return accounts.filter((account) => {
        return (
          account.lineItems?.length ||
          args.initialAccountIds.includes(account.id)
        );
      });
    },
    {
      variables: {
        teamId,
        initialAccountIds: props.initialAccountIds,
      },
      queryKey: ['accounts'],
      keepPreviousData: true,
    }
  );

  return {
    data: queryData.data ?? [],
    isLoading: queryData.isLoading,
    isFetching: queryData.isFetching,
    error: queryData.error,
  };
}

export type FeeAccountRow = ReturnType<
  typeof useFeeAccountsQuery
>['data'][number];

export function useFeeAccountsTableFilter() {
  const [filter, setFilter] = useQueryParams({
    search: StringParam,
    line: StringParam,
  });

  return {
    filter,
    setFilter,
    resetFilter: useCallback(
      () => setFilter({ search: '', line: null }),
      [setFilter]
    ),
  };
}

export const FeeAccountsTable = (props: Props) => {
  const methods = useFeeForm();
  const { colorScheme } = useMantineColorScheme();
  const { colors } = useMantineTheme();

  const { filter, resetFilter } = useFeeAccountsTableFilter();

  const queryData = useFeeAccountsQuery(props);

  const trimmed = filter.search?.trim();

  const options = useMemo(() => {
    const data = queryData.data ?? [];

    const hasFilter = trimmed || filter.line;

    if (hasFilter) {
      return data.filter((account) => {
        const inTitle = !trimmed
          ? true
          : account.title.toLowerCase().includes(trimmed.toLowerCase());

        const inMappings = !trimmed
          ? true
          : account.lineItems.some((mapping) => {
              return mapping.line.toLowerCase().includes(trimmed.toLowerCase());
            });

        const inLineType = !filter.line
          ? true
          : account.lineItems.some((mapping) => {
              return mapping.lineType === filter.line;
            });

        return (inTitle || inMappings) && inLineType;
      });
    }

    return data;
  }, [queryData.data, trimmed, filter.line]);

  const columns = useMemo<MRT_ColumnDef<FeeAccountRow>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Account',
        Cell: ({ row }) => {
          return (
            <Box>
              <Text>{row.original.title}</Text>
              <Text c="gray" size="xs">
                Included financials:{' '}
                {row.original.lineItems?.length
                  ? row.original.lineItems.map((item, index, arr) => {
                      const hasNext = index < arr.length - 1;

                      return (
                        <Fragment key={item.lineType}>
                          <Tooltip label={item.lineType} withArrow>
                            <Text size="xs" span>
                              {item.line}
                            </Text>
                          </Tooltip>
                          {hasNext && (
                            <Text size="xs" span>
                              ,{' '}
                            </Text>
                          )}
                        </Fragment>
                      );
                    })
                  : '-'}
              </Text>
            </Box>
          );
        },
      },
    ],
    []
  );

  return (
    <Controller
      control={methods.control}
      name="linkedAccountIds"
      rules={{
        validate: (value) => {
          if (!value?.length) return 'At least one account is required';
          return true;
        },
      }}
      render={({ field, fieldState }) => {
        return (
          <>
            <Box
              sx={(theme) =>
                Object.assign({
                  backgroundColor:
                    colorScheme === 'dark'
                      ? theme.colors.dark[6]
                      : theme.colors.neutral[1],
                  padding: '0.4rem 0.7rem',
                  borderRadius: theme.radius.md,
                  // minHeight: 35,
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: theme.spacing.md,
                })
              }
            >
              <Text size="sm" fw={500}>
                Accounts included in the fee
                <Text
                  c={colorScheme === 'dark' ? colors.gray[6] : 'gray'}
                  ml="xs"
                  size="xs"
                  component="span"
                >
                  {field.value?.length ?? 0}
                </Text>
              </Text>
            </Box>

            {fieldState.error && (
              <Alert title={'Validation'} color="red">
                {fieldState.error.message}
              </Alert>
            )}
            <LazyTable
              data={{
                loading: queryData.isLoading,
                rows: options,
                rowCount: options.length,
                error: queryData.error?.message,
              }}
              table={{
                columns,
                hideSettings: true,
                hideHeader: true,
                emptyRowsFallback: () => (
                  <Center>
                    <Text size="sm" c="gray">
                      No accounts found
                    </Text>
                  </Center>
                ),
              }}
              pagination={{
                pagination: {
                  pageIndex: 0,
                  pageSize: options.length,
                },
                setPagination: undefined,
              }}
              selecting={{
                rowSelection: {
                  rows:
                    field.value?.reduce(
                      (acc, id) => {
                        acc[id] = true;
                        return acc;
                      },
                      {} as Record<string, boolean>
                    ) ?? {},
                  allPagesSelected: false,
                },
                disableSelectAll: true,
                setRowSelection: (select) => {
                  const ids = Object.entries(select)
                    .filter(([_, selected]) => selected)
                    .map(([id]) => id);

                  field.onChange(ids);
                },
                setAllPagesSelected: () => {},
              }}
              resetFilter={resetFilter}
            >
              <Group>
                <SearchFilter />
                <LineTypeFilter />
              </Group>
            </LazyTable>
          </>
        );
      }}
    />
  );
};

const SearchFilter = () => {
  const { filter, setFilter } = useFeeAccountsTableFilter();

  return (
    <Filter.Search
      value={filter.search || ''}
      setValue={(value) => setFilter({ search: value })}
    />
  );
};

const LineTypeFilter = () => {
  const { filter, setFilter } = useFeeAccountsTableFilter();
  const [teamId] = useTeamId();

  const {
    data: lineTypes = [],
    isLoading,
    error,
  } = useQuery(
    (q, args) => {
      return q
        .paymentLineClassifications({
          where: {
            lines: {
              tenantId: { _eq: args.teamId },
              paymentId: { _is_null: true },
            },
          },
          order_by: [{ name: 'asc_nulls_last' }],
        })
        .map((line) => ({
          value: line.name || '-',
          label: toTitleCase(line.name?.split('_').reverse()[0]) || '-',
        }));
    },
    {
      variables: {
        teamId,
      },
      keepPreviousData: true,
    }
  );

  const value = useMemo(
    () => lineTypes.find((x) => x.value === filter.line) ?? null,
    [filter.line, lineTypes]
  );

  return (
    <Filter.Select
      type="single"
      label="Financial line"
      setValue={(value) => setFilter({ line: value?.value })}
      value={value}
      data={{
        options: lineTypes,
        error: error,
        loading: isLoading,
      }}
    />
  );
};
