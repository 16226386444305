import { LazyTable, MRT_ColumnDef } from '@finalytic/table';
import { formatCurrency } from '@finalytic/utils';
import { Box, Center } from '@mantine/core';
import { Text } from '@mantine/core';
import { useMemo } from 'react';
import { useFeeForm } from '../../useFeeForm';
import {
  FeeReservationPreviewCalculationProps,
  useFeeReservationPreviewCalculation,
} from './useFeeReservationPreviewCalculation';

export const FeeReservationPreviewTotal = (
  props: FeeReservationPreviewCalculationProps
) => {
  const { selectedSumCentTotal } = useFeeReservationPreviewCalculation(props);

  const methods = useFeeForm();
  const feeTitle = methods.watch('title');

  const rowData = useMemo(() => {
    return [
      {
        id: 'xxx',
        centTotal: selectedSumCentTotal,
        currency: props.reservation?.currency ?? 'usd',
        title: feeTitle || '',
        isAdjustment: false,
      },
    ];
  }, [selectedSumCentTotal, feeTitle, props.reservation?.currency]);

  const columns = useMemo<MRT_ColumnDef<(typeof rowData)[number]>[]>(
    () => [
      {
        header: 'Title',
        accessorKey: 'title',
        mantineTableFooterCellProps: {
          pl: 'xs',
        },
        mantineTableBodyCellProps: (props) => ({
          sx: (theme) => ({
            backgroundColor:
              props.row.index === 0 ? theme.colors.yellow[0] : undefined,
            fontWeight: 500,
          }),
        }),
      },
      {
        header: 'amount',
        accessorKey: 'centTotal',
        maxSize: 0,
        minSize: 90,
        size: 90,
        mantineTableBodyCellProps: (props) => ({
          align: 'right',
          sx: (theme) => ({
            backgroundColor:
              props.row.index === 0 ? theme.colors.yellow[0] : undefined,
            fontWeight: 500,
          }),
        }),
        mantineTableFooterCellProps: {
          align: 'right',
          pr: 'xs',
        },
        Cell: ({ row }) => {
          return formatCurrency(
            row.original.centTotal / 100,
            row.original.currency
          );
        },
      },
    ],
    []
  );

  return (
    <Box
      sx={(theme) => ({
        table: {
          overflow: 'hidden',
          borderRadius: theme.radius.md,
        },
      })}
    >
      <LazyTable
        data={{
          rows: rowData,
          rowCount: rowData.length,
          loading: false,
          error: null,
        }}
        styles={{
          row: (props, theme) => ({
            '&:hover *': {
              backgroundColor: `${props.row.index === 0 ? theme.colors.yellow[0] : theme.white}!important`,
            },
          }),
        }}
        table={{
          columns,
          hideHeader: true,
          hideTopBar: true,
          emptyRowsFallback: () => (
            <Center>
              <Text size="sm" c="gray">
                No fees and commissions found
              </Text>
            </Center>
          ),
        }}
      />
    </Box>
  );
};
