import { useV2UI } from '@finalytic/ui';
import { useContext, useMemo } from 'react';
import { type Feature, getEnabledFeatures } from '../utils';
import { teamContext, useTeamRole, userContext } from './team';

export function useEnabledFeatures() {
  const user = useContext(userContext);
  const { team } = useContext(teamContext);
  const { isV2 } = useV2UI();

  return useMemo<Record<Feature, boolean>>(
    () =>
      getEnabledFeatures({
        isV2,
        teamFeatures: team.enabledFeatures,
        userApprovals: user.featureApprovals,
      }),
    [user.featureApprovals, team.enabledFeatures, isV2]
  );
}

export function useHasEnabledFeatures() {
  const user = useContext(userContext);
  const { isSuperAdmin, isPartnerAdmin } = useTeamRole();

  return useMemo<boolean>(() => {
    if (isSuperAdmin || isPartnerAdmin) return true;

    return !!user.featureApprovals.length;
  }, [user.featureApprovals, isSuperAdmin, isPartnerAdmin]);
}
