import {
  useEnabledFeatures,
  useInfiniteQuery,
  useTeamId,
} from '@finalytic/data';
import type { MRT_GroupingState, MRT_SortingState } from '@finalytic/table';
import { type Maybe, day } from '@finalytic/utils';
import {
  formatOwnerName,
  getListingAddress,
  getListingName,
  getSourceDescription,
  whereListingIsExcludedSetting,
  whereListingPeriod,
} from '@vrplatform/ui-common';
import { useWhereListings } from './useWhereListings';
type Params = {
  sorting: MRT_SortingState;
  type: 'mapping' | 'pmDashboard' | 'ownerPortal';
  groupBy: MRT_GroupingState;
  filterByAutomationId?: string;
};

export type ListingRow = NonNullable<
  ReturnType<typeof useListingTableQuery>['data']
>['pages'][number]['list'][number];

export const useListingTableQuery = ({
  sorting,
  type,
  groupBy,
  filterByAutomationId,
}: Params) => {
  const where = useWhereListings({ filterByAutomationId });
  const [teamId] = useTeamId();
  const { GL } = useEnabledFeatures();

  return useInfiniteQuery(
    (q, { sorting, where, type, groupBy, GL }, { limit, offset }) => {
      const aggregate = q.listingAggregate({ where }).aggregate?.count() || 0;

      const isPmDashboard = type === 'pmDashboard';

      const order_by = sorting.map((sort) => ({
        [sort.id]: sort.desc ? 'desc_nulls_last' : 'asc_nulls_last',
      }));

      const list = q
        .listings({
          where,
          limit,
          offset,
          order_by: groupBy?.[0]
            ? [
                {
                  collection: {
                    name: 'asc_nulls_last',
                  },
                },
                ...order_by,
              ]
            : order_by,
        })
        .map((listing) => {
          const disabledAutomations =
            isPmDashboard && !GL
              ? listing
                  .settingsRight({
                    where: whereListingIsExcludedSetting({
                      automationIds: undefined,
                      listingId: undefined,
                    }),
                    order_by: [{ updated_at: 'desc' }],
                  })
                  .map((setting) => ({
                    settingId: setting.id,
                    automationId: setting.automationId,
                  }))
              : [];

          const ownerships =
            isPmDashboard && !GL
              ? listing
                  .ownerships({
                    order_by: [{ createdAt: 'asc_nulls_last' }],
                    where: {
                      newOwnerId: { _is_null: false },
                    },
                  })
                  .map((ownership) => {
                    return {
                      ownershipId: ownership.id,
                      rowId: listing.id,
                      listingId: ownership.listingId,
                      listing: {
                        id: ownership.listing.id,
                        name: getListingName(ownership.listing),
                      },
                      role: ownership.role,
                      split: ownership.split,
                      vendor: ownership
                        .settingsLeft({ where: { key: { _eq: 'vendor' } } })
                        .map((setting) => ({
                          label: getSourceDescription(setting?.rightSource),
                          value: setting.value || '',
                          settingId: setting.id,
                        }))?.[0],
                      owner: {
                        id: ownership.newOwner?.id!,
                        firstName: ownership.newOwner?.firstName,
                        lastName: ownership.newOwner?.name,
                        companyName: ownership.newOwner?.name,
                        type: ownership.newOwner?.type || 'individual',
                      },
                    };
                  })
                  .filter((x) => x.owner.id)
              : [];

          const today = day().yyyymmdd();

          const currentOwnershipPeriod = !GL
            ? undefined
            : listing
                .ownershipPeriods({
                  limit: 1,
                  where: whereListingPeriod({
                    date: today,
                  }),
                })
                .map((period) => ({
                  id: period.id,
                  members: period
                    .members({
                      order_by: [
                        {
                          owner: {
                            name: 'asc_nulls_last',
                          },
                        },
                      ],
                    })
                    .map((owner) => ({
                      id: owner.id as string,
                      ownerId: owner.contactId as string,
                      ownerName: formatOwnerName(owner.contact),
                      ownerType: owner.contact?.companyType
                        ? ('company' as const)
                        : ('individual' as const),
                    })),
                }))[0];

          const currentRecurringFeeSubscriptions = !GL
            ? []
            : listing
                .recurringFeeSubscriptions({
                  where: {
                    _or: [
                      {
                        startAt: { _lte: today },
                        endAt: { _gte: today },
                      },
                      {
                        startAt: { _lte: today },
                        endAt: { _is_null: true },
                      },
                    ],
                  },
                })
                .map((subscripton) => ({
                  id: subscripton.id as string,
                  startAt: subscripton.startAt as string,
                  endAt: subscripton.endAt as Maybe<string>,
                  recurringFeeId: subscripton.recurringFeeId as string,
                }));

          const connections = GL
            ? [
                {
                  id: listing.connectionId,
                  name: listing.connection?.name,
                  iconRound: listing.connection?.app.iconRound,
                },
              ]
            : listing
                .connections({
                  order_by: [{ connection: { app: { name: 'asc' } } }],
                })
                .map((conn) => ({
                  id: conn.id,
                  name: conn.name,
                  iconRound: conn.connection.app.iconRound,
                }));

          const collection = {
            id: isPmDashboard ? listing.collectionId : '',
            name: listing.collection?.name || 'Ungrouped',
          };

          return {
            id: listing.id,
            currentOwnershipPeriod,
            currentRecurringFeeSubscriptions,
            name: getListingName(listing),
            address: getListingAddress(listing).full,
            collection: collection.id
              ? { id: collection.id, name: collection.name }
              : null,
            tenantId: listing.tenantId,
            pmsConnectionId: listing.connectionId,
            connections,
            status: listing.status,
            pmsStatus: listing.pmsStatus,
            sourceStatus: listing.source?.status,
            statements: isPmDashboard
              ? listing
                  .ownerStatements_aggregate({
                    order_by: [{ startAt: 'desc' }],
                    where: {
                      owners: {
                        role: { _eq: 'owner' },
                      },
                    },
                  })
                  ?.aggregate?.count() || 0
              : 0,
            ownerships,
            disabledAutomations,
          };
        });

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'listings',
      variables: {
        sorting,
        where,
        type,
        teamId,
        groupBy,
        GL,
      },
    }
  );
};
